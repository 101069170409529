var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "award-details-container"
  }, [_c("section", {
    staticClass: "award-details-top"
  }, [_c("a-form-model", {
    attrs: {
      layout: "inline",
      form: _vm.queryParams
    }
  }, [_c("a-form-model-item", [_c("a-range-picker", {
    attrs: {
      allowClear: "",
      "show-time": "",
      valueFormat: "YYYY-MM-DD HH:mm:ss",
      placeholder: ["结算开始", "结算结束"]
    },
    model: {
      value: _vm.queryParams.settlementdate,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "settlementdate", $$v);
      },
      expression: "queryParams.settlementdate"
    }
  })], 1), _c("a-form-model-item", [_c("a-input", {
    attrs: {
      placeholder: "用户名称"
    },
    model: {
      value: _vm.queryParams.username,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "username", $$v);
      },
      expression: "queryParams.username"
    }
  })], 1), _c("a-form-model-item", [_c("a-select", {
    attrs: {
      placeholder: "奖励类型",
      allowClear: ""
    },
    model: {
      value: _vm.queryParams.type,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "type", $$v);
      },
      expression: "queryParams.type"
    }
  }, _vm._l(_vm.optionTypes, function (ele) {
    return _c("a-select-option", {
      key: ele.type,
      attrs: {
        value: ele.type
      }
    }, [_vm._v(" " + _vm._s(ele.desc) + " ")]);
  }), 1)], 1), _c("a-form-model-item", [_c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(" 查询 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "html-type": "reset"
    },
    on: {
      click: _vm.handleReset
    }
  }, [_vm._v(" 重置 ")])], 1)], 1)], 1), _c("section", {
    staticClass: "award-details-main"
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.dataSource,
      pagination: false,
      loading: _vm.loading,
      rowKey: "taskid"
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function fn(type) {
        return [_vm._v(" " + _vm._s(_vm.formatType(type)) + " ")];
      }
    }, {
      key: "awardtime",
      fn: function fn(awardtime) {
        return [_vm._v(" " + _vm._s(_vm.formatData(awardtime)) + " ")];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.queryParams.page,
      size: _vm.queryParams.size,
      onPageSizeChange: _vm.onPageChange,
      onShowSizeChange: _vm.onPageChange
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.queryParams, "page", $event);
      },
      "update:size": function updateSize($event) {
        return _vm.$set(_vm.queryParams, "size", $event);
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };